"use client";

import { usePathname } from "next/navigation";
import { useEffect } from "react";

import { fetchWrapper } from "@/lib/hooks/fetch-client";

const MissingPageLogger = () => {
	const pathname = usePathname();

	useEffect(() => {
		const log404Page = async () => {
			await fetchWrapper("/v1/missing", {
				method: "POST",
				body: JSON.stringify({
					url: pathname,
				}),
			});
		};

		log404Page();
	}, [pathname]);

	return null;
};

export default MissingPageLogger;
