"use client";

import Image from "next/image";
import Link from "next/link";
import React from "react";
import { UrlObject } from "url";

export default function SocialLinks({ socials }) {
	const trackSocialVisit = ({ social }) => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "social_visit",
			network: social,
		});
	};

	return (
		<div className="mt-4 flex items-center justify-center gap-4 md:justify-start">
			{socials.map(
				(social: {
					link: string | UrlObject;
					name: string;
					icon?: React.ElementType;
					brandColor?: string;
				}) => (
					<Link
						href={social.link}
						className="grid size-8 shrink-0 place-content-center rounded-full"
						key={social.name}
						onClick={() => trackSocialVisit({ social: social.name })}
					>
						{social?.icon ? (
							React.createElement(social?.icon, {
								className: "size-5",
								style: { color: social?.brandColor },
								alt: social.name,
								title: social.name,
							})
						) : (
							<Image
								width={20}
								height={20}
								src={`/social-icons/${social.name}.svg`}
								alt={social.name}
							/>
						)}
					</Link>
				)
			)}
		</div>
	);
}
